// app/not-found/page.jsx
'use client';

import Link from "next/link";

export default function NotFoundPage() {
    return (
        <div className="flex items-center justify-center min-h-[80vh] bg-gray-100">
            <div className="text-center">
                <h1 className="text-5xl font-bold text-6ixshooter-red mb-4">404 - Not Found</h1>
                <p className="text-xl text-gray-800 mb-6">
                    The page you are looking for does not exist.
                </p>
                <p className="text-lg text-gray-600 mb-4">
                    It might have been removed or you may have entered an incorrect URL.
                </p>
                <Link
                    href="/"
                    className="bg-6ixshooter-blue text-white font-semibold p-3 rounded-md shadow-md hover:bg-6ixshooter-blue-hover transition duration-300"
                >
                    Go Back Home
                </Link>
            </div>
        </div>
    );
}